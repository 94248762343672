<template>
  <div class="batch-solutions-page dark-bg">
    <HeroBlock
      :image="image"
      :icon="icon"
      :title="title"
    />
    <bread-crumb></bread-crumb>
    <p class="batch-solutions-page__descr">
      <pre>
        <strong>Пакетні рішення</strong>
        - Ми дотримуємося незмінних принципів в роботі на високому рівні професіоналізму та чіткого розуміння потреб клієнтів.
        Більше 1000 компаній по всій країні користуються нашими пакетними рішеннями
      </pre>
    </p>
    <BatchSolutions
      :data="slider"
    />
    <FormCommon />
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const HeroBlock = defineAsyncComponent(() =>
  import("@/components/HeroBlock.vue")
);
const FormCommon = defineAsyncComponent(() =>
  import("@/components/FormCommon.vue")
);
const BatchSolutions = defineAsyncComponent(() =>
  import("@/components/BatchSolutions.vue")
);
const BreadCrumb = defineAsyncComponent(() =>
  import("@/components/BreadCrumb.vue")
);

import { useMeta } from "vue-meta";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import axios from "axios";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    useMeta({
      title: t("BatchSolutions Page"),
      htmlAttrs: {
        lang: localStorage.getItem("VueAppLanguage"),
        amp: true,
      },
    });
    return {
      t,
    };
  },
  props: {
    url: {
      type: String,
      employee: Object,
      default: process.env.VUE_APP_API,
    },
  },
  data() {
    return {
      locale: this.$i18n.locale,
      image: "",
      icon: "",
      title: "",
      titleOurCustomers: "",
      titleOurPartners: "",
      dataOurCustomers: [],
      dataOurPartners: [],
      slider: [],
    };
  },
  components: {
    FormCommon,
    BatchSolutions,
    HeroBlock,
    BreadCrumb,
  },
  methods: {
    async getBanner() {
      try {
        let clear_url = this.$route.matched[0].path.replace("/uk/", "");
        clear_url = clear_url.replace("/ru/", "");
        clear_url = clear_url.replace("/", "");

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API +
            "/api/" +
            this.locale +
            "/banners/search/" +
            clear_url,
          config
        );
        this.image = response.data.data[0].image;
        this.icon = response.data.data[0].icon[0].url;
        this.title = response.data.data[0].title;
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
    async getSliders() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API + "/api/" + this.locale + "/all-sliders",
          config
        );
        this.slider = response.data.home_slider.data.services.data;
        this.dataOurCustomers = response.data.our_customer_slider.attachment;
        this.dataOurPartners = response.data.our_partner_slider.attachment;
        this.titleOurCustomers = response.data.our_customer_slider.name;
        this.titleOurPartners = response.data.our_partner_slider.name;
      } catch (e) {
        // alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
  },
  mounted() {
    this.getBanner();
    this.getSliders();
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.batch-solutions-page {
  &__descr {
    max-width: 980px;
    padding: 0 15px;
    margin: 0 auto;
    @include property("margin-top", 40, 20);
    @include property("margin-bottom", 50, 25);
    line-height: calc(25 / 16);
    color: #fff;
  }
}
</style>
